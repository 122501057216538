import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { apiService } from '../../services/api';

const VehicleForm = ({ customerId, onSuccess, isDialog = false }) => {
  const navigate = useNavigate(); // Add this line
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');
  const [isDealerVehicle, setIsDealerVehicle] = useState(false);
  const [potentialDuplicates, setPotentialDuplicates] = useState([]);
  
  const [formData, setFormData] = useState({
    rego: '',
    stock: '',
    make: '',
    model: '',
    version: '',
    year: '',
    type: '',
    vin: '',
    customer_id: customerId || null
  });

  // Effect to check for potential duplicates
  useEffect(() => {
    const checkDuplicates = async () => {
      if (!formData.make || !formData.model) return;
      
      try {
        setChecking(true);
        const response = await apiService.searchVehicles({
          make: formData.make,
          model: formData.model,
          year: formData.year,
          rego: formData.rego,
          stock: formData.stock
        });
        
        if (response.data && response.data.length > 0) {
          setPotentialDuplicates(response.data);
        } else {
          setPotentialDuplicates([]);
        }
      } catch (err) {
        console.error('Error checking duplicates:', err);
      } finally {
        setChecking(false);
      }
    };

    // Debounce the check to avoid too many API calls
    const timeoutId = setTimeout(checkDuplicates, 500);
    return () => clearTimeout(timeoutId);
  }, [formData.make, formData.model, formData.year, formData.rego, formData.stock]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    // Dealer vehicles must have stock number, regular vehicles must have rego
    if (isDealerVehicle && !formData.stock) {
      setError('Stock number is required for dealer vehicles');
      return false;
    }
    if (!isDealerVehicle && !formData.rego) {
      setError('Registration number is required for registered vehicles');
      return false;
    }
    if (!formData.make || !formData.model) {
      setError('Make and Model are required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError('');

      // Clean the form data before sending
      const cleanedFormData = {
        ...formData,
        // Convert empty VIN to null
        vin: formData.vin.trim() || null,
        is_dealer_vehicle: isDealerVehicle
      };

      // Create vehicle
      const vehicleResponse = await apiService.createVehicle(cleanedFormData);

      if (vehicleResponse.status && customerId) {
        const ownershipData = {
          vehicle_id: parseInt(vehicleResponse.id),
          customer_id: parseInt(customerId),
          start_date: new Date().toISOString().split('T')[0]
        };
        
        try {
          await apiService.addOwnership(ownershipData);
        } catch (ownershipError) {
          console.error('Ownership creation failed:', ownershipError);
          throw ownershipError;
        }
      }

      if (isDialog && onSuccess) {
        onSuccess({
          id: vehicleResponse.id,
          ...cleanedFormData
        });
      } else {
        navigate(customerId ? `/customers/${customerId}/profile` : '/vehicles');
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.response?.data?.message || 'Failed to save vehicle');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (isDialog) {
      onSuccess(null);
    } else {
      navigate(customerId ? `/customers/${customerId}/profile` : '/vehicles');
    }
  };

  const renderDuplicateWarning = () => {
    if (potentialDuplicates.length === 0) return null;

    return (
      <Alert severity="warning" sx={{ mb: 2 }}>
        Potential duplicate vehicles found:
        <ul>
          {potentialDuplicates.map((vehicle, index) => (
            <li key={index}>
              {vehicle.make} {vehicle.model} - {vehicle.rego || vehicle.stock} 
              ({vehicle.is_dealer_vehicle ? 'Dealer Stock' : 'Registered'})
            </li>
          ))}
        </ul>
      </Alert>
    );
  };

  const formContent = (
    <Box component="form" onSubmit={handleSubmit}>
      {renderDuplicateWarning()}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={isDealerVehicle}
                onChange={(e) => setIsDealerVehicle(e.target.checked)}
              />
            }
            label="Dealer Vehicle"
          />
          <Tooltip title="Toggle for dealer stock vehicles without registration">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} md={6}>
          {isDealerVehicle ? (
            <TextField
              fullWidth
              required
              name="stock"
              label="Stock Number"
              value={formData.stock}
              onChange={handleChange}
              error={isDealerVehicle && !formData.stock}
              helperText={isDealerVehicle && !formData.stock ? 'Stock number is required' : ''}
            />
          ) : (
            <TextField
              fullWidth
              required
              name="rego"
              label="Registration Number"
              value={formData.rego}
              onChange={handleChange}
              error={!isDealerVehicle && !formData.rego}
              helperText={!isDealerVehicle && !formData.rego ? 'Registration number is required' : ''}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="vin"
            label="VIN/Chassis Number (Optional)"
            value={formData.vin}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="make"
            label="Make"
            value={formData.make}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            name="model"
            label="Model"
            value={formData.model}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="version"
            label="Version/Trim"
            value={formData.version}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="year"
            label="Year"
            type="number"
            value={formData.year}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              label="Type"
            >
              <MenuItem value="ICE">ICE</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
              <MenuItem value="EV">EV</MenuItem>
              <MenuItem value="PHEV">PHEV</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || checking}
            >
              {loading ? <CircularProgress size={24} /> : 'Save Vehicle'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  if (isDialog) {
    return formContent;
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Add New Vehicle {customerId ? `for Customer #${customerId}` : ''}
      </Typography>
      {formContent}
    </Paper>
  );
};

export default VehicleForm;