// src/routes/index.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../components/Auth/ProtectedRoute';
import MainLayout from '../components/Layout/MainLayout';
import LoginForm from '../components/Auth/LoginForm';
import Dashboard from '../components/Dashboard/Dashboard';
import CustomerList from '../components/Customers/CustomerList';
import CustomerForm from '../components/Customers/CustomerForm';
import VehicleList from '../components/Vehicles/VehicleList';
import VehicleForm from '../components/Vehicles/VehicleForm';
import CustomerProfile from '../components/Customers/CustomerProfile';
import VehicleProfile from '../components/Vehicles/VehicleProfile';
import ServiceList from '../components/Services/ServiceList';
import ServiceTemplateList from '../components/Services/ServiceTemplateManage';
import BookingForm from '../components/Bookings/BookingForm';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      
      <Route path="/" element={
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      }>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="customers" element={<CustomerList />} />
        <Route path="customers/new" element={<CustomerForm />} />
        <Route path="customers/:id" element={<CustomerForm />} />
        <Route path="vehicles" element={<VehicleList />} />
        <Route path="vehicles/new" element={<VehicleForm />} />
        <Route path="vehicles/:id" element={<VehicleForm />} />
        <Route path="customers/:id/profile" element={<CustomerProfile />} />
        <Route path="vehicles" element={<VehicleList />} />
        <Route path="vehicles/:id" element={<VehicleProfile />} />
        <Route path='services' element={<ServiceList/>} />
        <Route path='service-templates' element={<ServiceTemplateList/>} />
        <Route path="bookings/new" element={<BookingForm />} />
      
      </Route>

      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default AppRoutes;