import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { apiService } from '../../services/api';

const CustomerForm = ({ onSuccess, isDialog = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    phone: '',
    mobile: '',
    email: '',
  });

  useEffect(() => {
    if (id) {
      loadCustomer();
    }
  }, [id]);

  const loadCustomer = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await apiService.getCustomer(id);
      const customerData = response.data?.data || response.data || {};
      
      setCustomer({
        name: customerData.name || '',
        address: customerData.address || '',
        phone: customerData.phone || '',
        mobile: customerData.mobile || '',
        email: customerData.email || '',
      });
    } catch (err) {
      console.error('Error loading customer:', err);
      setError('Failed to load customer data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      setError('');

      const customerData = {
        name: customer.name,
        address: customer.address || null,
        phone: customer.phone || null,
        mobile: customer.mobile || null,
        email: customer.email || null,
      };

      let response;
      if (id) {
        response = await apiService.updateCustomer(id, customerData);
      } else {
        response = await apiService.createCustomer(customerData);
      }

      // Handle success
      if (isDialog && onSuccess) {
        // If in dialog mode, call success callback with new customer data
        onSuccess({
          id: response.id,
          ...customerData
        });
      } else {
        // If not in dialog mode, navigate based on context
        navigate(id ? `/customers/${id}/profile` : '/customers');
      }
    } catch (err) {
      console.error('Error saving customer:', err);
      setError(err.response?.data?.message || 'Failed to save customer. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    if (isDialog) {
      onSuccess(null);
    } else {
      if (id) {
        navigate(`/customers/${id}/profile`);
      } else {
        navigate('/customers');
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const formContent = (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="name"
            label="Name"
            value={customer.name}
            onChange={(e) => setCustomer(prev => ({
              ...prev,
              name: e.target.value
            }))}
            error={!customer.name}
            helperText={!customer.name ? 'Name is required' : ''}
            disabled={saving}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            name="address"
            label="Address"
            value={customer.address || ''}
            onChange={(e) => setCustomer(prev => ({
              ...prev,
              address: e.target.value
            }))}
            disabled={saving}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="phone"
            label="Phone"
            value={customer.phone || ''}
            onChange={(e) => setCustomer(prev => ({
              ...prev,
              phone: e.target.value
            }))}
            disabled={saving}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="mobile"
            label="Mobile"
            value={customer.mobile || ''}
            onChange={(e) => setCustomer(prev => ({
              ...prev,
              mobile: e.target.value
            }))}
            disabled={saving}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            value={customer.email || ''}
            onChange={(e) => setCustomer(prev => ({
              ...prev,
              email: e.target.value
            }))}
            error={customer.email && !/\S+@\S+\.\S+/.test(customer.email)}
            helperText={
              customer.email && !/\S+@\S+\.\S+/.test(customer.email)
                ? 'Invalid email format'
                : ''
            }
            disabled={saving}
          />
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              onClick={handleCancel}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving || !customer.name}
              sx={{ minWidth: 100 }}
            >
              {saving ? <CircularProgress size={24} /> : id ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );

  if (isDialog) {
    return formContent;
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/customers')}
        >
          Back to Customers
        </Button>
        <Typography variant="h5" component="h1">
          {id ? 'Edit Customer' : 'New Customer'}
        </Typography>
      </Box>

      {formContent}
    </Paper>
  );
};

export default CustomerForm;