import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Button,
  Typography,
  Stack,
  Avatar,
  AvatarGroup,
  IconButton,
  Chip,
  Card,
  CardContent,
  Tooltip,
  styled,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  AccessTime as TimeIcon,
  Event as EventIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { format, addDays, startOfToday, isToday, isSameDay } from 'date-fns';

const TimeSlot = styled(Card)(({ theme, isBooked, isSelected }) => ({
  cursor: isBooked ? 'default' : 'pointer',
  height: '100%',
  transition: 'all 0.2s ease',
  borderLeft: isBooked ? `4px solid ${theme.palette.primary.main}` : 'none',
  backgroundColor: isSelected 
    ? theme.palette.primary.light 
    : isBooked 
      ? theme.palette.grey[50] 
      : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: isBooked 
      ? theme.palette.grey[50] 
      : theme.palette.primary.light,
    transform: isBooked ? 'none' : 'translateY(-2px)',
    boxShadow: isBooked ? theme.shadows[1] : theme.shadows[3],
  },
}));

const TimeSlotGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 2fr))',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const ModernBookingCalendar = ({
  availableSlots,
  selectedSlot,
  onSlotSelect,
}) => {
  const [currentDate, setCurrentDate] = useState(startOfToday());

  const handleDateChange = (days) => {
    setCurrentDate(prev => addDays(prev, days));
  };

  const todaySlots = useMemo(() => {
    const dateStr = format(currentDate, 'yyyy-MM-dd');
    return availableSlots.filter(slot => slot.date === dateStr);
  }, [availableSlots, currentDate]);

  return (
    <Box>
      {/* Calendar Header */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton onClick={() => handleDateChange(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Button
            variant="outlined"
            startIcon={<EventIcon />}
            onClick={() => setCurrentDate(startOfToday())}
          >
            Today
          </Button>
          <IconButton onClick={() => handleDateChange(1)}>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        <Typography variant="h6">
          {format(currentDate, 'MMMM d, yyyy')}
        </Typography>
      </Box>

      {/* Time Slots */}
      <Box sx={{ height: 'calc(100vh - 400px)', overflow: 'auto' }}>
        <TimeSlotGrid>
          {todaySlots.map((slot) => {
            const isBooked = slot.bookings?.length > 0;
            const isSelected = selectedSlot?.date === slot.date && 
                             selectedSlot?.time === slot.time;

            return (
              <TimeSlot
                key={`${slot.date}-${slot.time}`}
                isBooked={isBooked}
                isSelected={isSelected}
                onClick={() => !isBooked && onSlotSelect(slot)}
              >
                <CardContent>
                  <Stack spacing={1}>
                    <Typography variant="h6">
                      {slot.time}
                    </Typography>
                    {isBooked ? (
                      slot.bookings.map((booking) => (
                        <Tooltip
                          key={booking.id}
                          title={
                            <Box sx={{ p: 1 }}>
                              <Typography variant="body2">
                                {booking.services}
                              </Typography>
                              <Typography variant="body2">
                                Duration: {booking.duration} minutes
                              </Typography>
                            </Box>
                          }
                        >
                          <Stack spacing={1}>
                            <AvatarGroup max={3} sx={{ justifyContent: 'flex-end' }}>
                              {booking.technicians.map((tech) => (
                                <Avatar key={tech.id} sx={{ width: 24, height: 24 }}>
                                  {tech.initials}
                                </Avatar>
                              ))}
                            </AvatarGroup>
                            <Chip 
                              icon={<TimeIcon />} 
                              label={`${booking.duration} min`}
                              size="small"
                            />
                          </Stack>
                        </Tooltip>
                      ))
                    ) : (
                      <Chip
                        icon={<PersonIcon />}
                        label={`${slot.available_spots} spots`}
                        color="primary"
                        variant="outlined"
                      />
                    )}
                  </Stack>
                </CardContent>
              </TimeSlot>
            );
          })}
        </TimeSlotGrid>
      </Box>
    </Box>
  );
};

export default ModernBookingCalendar;