import React, { useMemo } from 'react';
import BuildIcon from '@mui/icons-material/Build';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Stack,
  Avatar,
  AvatarGroup,
  Chip,
  TextField,
  Tooltip,
  IconButton,
  Collapse,
  Button,
  InputAdornment,
} from '@mui/material';
import {
  AccessTime as TimeIcon,
  ExpandMore as ExpandMoreIcon,
  Person as PersonIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import TechnicianSelection from './TechnicianSelection'; 
const BookingCard = ({ booking }) => {
  const [expanded, setExpanded] = React.useState(false);

  if (!booking) return null;

  const technicians = booking.technicians || [];
  const services = booking.services || [];
  const duration = booking.duration || 0;

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        border: '1px solid #e0e0e0',
        '&:hover': {
          boxShadow: 2,
        }
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {booking.requested_time_slot || 'No time specified'}

          </Typography>

          <Stack direction="row" spacing={1} alignItems="right">
          {technicians.length > 0 && (
              <AvatarGroup 
                max={3} 
                sx={{ 
                  '& .MuiAvatar-root': { 
                    width: 22, 
                    height: 22,
                    fontSize: '0.875rem'
                  } 
                }}
              >
                {technicians.map((tech) => (
                  <Tooltip key={tech.id} title={tech.name || ''}>
                    <Avatar>{tech.initials || '??'}</Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            )}
            <Chip
              icon={<TimeIcon sx={{ fontSize: '1rem' }} />}
              label={`${duration} min`}
              size="small"
              color="primary"
            />


          </Stack>

        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="text.secondary">
          {booking.vehicle_info || 'No vehicle info'}
          </Typography>
          <Button
              size="small"
              
              onClick={() => setExpanded(!expanded)}
              endIcon={<BuildIcon 
                sx={{ 
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: '0.2s',
                }} 
              />}
            >
            </Button>
            </Stack>
        {services.length > 0 && (
          <>


            <Collapse in={expanded}>
              <Box sx={{ mt: 1 }}>
                {services.map((service, index) => (
                  <Paper
                    key={index}
                    variant="outlined"
                    sx={{ p: 1, mb: 1 }}
                  >
                    <Stack 
                      direction="row" 
                      justifyContent="space-between" 
                      alignItems="center"
                    >
                      <Typography variant="body2">{service.name || 'Unnamed service'}</Typography>
                      <Chip
                        size="small"
                        label={`${service.duration || 0} min`}
                        variant="outlined"
                      />
                    </Stack>
                  </Paper>
                ))}
              </Box>
            </Collapse>
          </>
        )}
      </Stack>
    </Paper>
  );
};

const BookingDateSelector = ({
  availableSlots,
  selectedDate,
  selectedTime,
  onDateChange,
  onTimeChange,
  selectedTechnicians,
  technicians,
  onTechniciansChange,
  bookingNotes,
  onNotesChange,
  submitted,
}) => {
  const bookingsForDate = useMemo(() => {
    if (!selectedDate || !Array.isArray(availableSlots)) return [];
    
    const validBookings = availableSlots
      .filter(slot => {
        try {
          return slot && slot.date === format(selectedDate, 'yyyy-MM-dd');
        } catch (error) {
          return false;
        }
      })
      .filter(slot => slot.requested_time_slot);

    return validBookings.sort((a, b) => {
      const timeA = a.requested_time_slot || '';
      const timeB = b.requested_time_slot || '';
      return timeA.localeCompare(timeB);
    });
  }, [availableSlots, selectedDate]);

  return (
    <Grid container spacing={3}>
      {/* Left Side Content */}
      <Grid item xs={12} md={7}>
        <Stack spacing={3}>
          {/* Date and Time Selection */}
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Select Date"
                  value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
                  onChange={(e) => {
                    try {
                      const date = e.target.value ? parseISO(e.target.value) : null;
                      onDateChange(date);
                    } catch (error) {
                      console.error('Invalid date:', error);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="time"
                  label="Select Time"
                  value={selectedTime || ''}
                  onChange={(e) => onTimeChange(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimeIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Assign Technicians */}
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Assign Technicians
            </Typography>
            <TechnicianSelection
              technicians={technicians}
              selectedTechnicians={selectedTechnicians}
              onChange={onTechniciansChange}
              required
              error={submitted && selectedTechnicians.length === 0 ? 
                    'At least one technician is required' : null}
            />
          </Paper>

          {/* Additional Notes */}
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Additional Notes
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder="Add any special instructions or notes here..."
              value={bookingNotes}
              onChange={(e) => onNotesChange(e.target.value)}
            />
          </Paper>
        </Stack>
      </Grid>

      {/* Right Side - Existing Bookings */}
      <Grid item xs={12} md={5}>
        <Paper sx={{ p: 2, height: 'calc(100vh - 200px)', overflow: 'auto' }}>
          <Typography variant="h6" gutterBottom>
            Existing Bookings for {selectedDate ? format(selectedDate, 'MMMM d, yyyy') : 'Selected Date'}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {bookingsForDate.length > 0 ? (
              bookingsForDate.map((booking, index) => (
                <BookingCard key={index} booking={booking} />
              ))
            ) : (
              <Typography color="text.secondary" align="center" py={4}>
                No bookings found for this date
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BookingDateSelector;