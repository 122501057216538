import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  OutlinedInput,
  Stack,
  Typography,
  Avatar,
} from '@mui/material';

const TechnicianSelection = ({
  technicians,
  selectedTechnicians,
  onChange,
  error,
  required = false,
}) => {
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id="technician-selection-label">
        {required ? 'Assign Technicians *' : 'Assign Technicians'}
      </InputLabel>
      <Select
        labelId="technician-selection-label"
        multiple
        value={selectedTechnicians || []}
        onChange={handleChange}
        input={<OutlinedInput label={required ? 'Assign Technicians *' : 'Assign Technicians'} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((techId) => {
              const technician = technicians.find(t => t.id === techId);
              if (!technician) return null;
              return (
                <Chip
                  key={techId}
                  avatar={
                    <Avatar>
                      {technician.username.substring(0, 2).toUpperCase()}
                    </Avatar>
                  }
                  label={technician.username}
                  size="small"
                />
              );
            })}
          </Box>
        )}
      >
        {technicians.map((technician) => (
          <MenuItem key={technician.id} value={technician.id}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ width: 24, height: 24 }}>
                {technician.username.substring(0, 2).toUpperCase()}
              </Avatar>
              <Typography>{technician.username}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default TechnicianSelection;