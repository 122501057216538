import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  FormControlLabel,
  Switch,
  Stack,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Timer as TimerIcon,
  Assignment as AssignmentIcon,
} from '@mui/icons-material';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/api';
import CustomerForm from '../Customers/CustomerForm';
import VehicleForm from '../Vehicles/VehicleForm';
import BookingTimeGrid from './ModernBookingCalendar';
import TechnicianSelection from './MultyTechnicians';
import BookingDateSelector from './BookingTimeComponent';

import ModernBookingCalendar from './ModernBookingCalendar';
import { format } from 'date-fns';

const steps = ['Select Customer', 'Select Vehicle', 'Select Services', 'Schedule Booking'];

const BookingForm = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [customers, setCustomers] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [useTemplate, setUseTemplate] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceTemplates, setServiceTemplates] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [bookingData, setBookingData] = useState({
    requested_date: '',
    requested_time_slot: '',
    notes: ''
  });
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    loadCustomers();
    loadAllVehicles();
    loadServices();
    loadServiceTemplates();
    const loadTechnicians = async () => {
      try {
        const response = await apiService.getUsersByRole('technician');
        setTechnicians(response.data || []);
      } catch (err) {
        console.error('Error loading technicians:', err);
      }
    };
    loadTechnicians();
  }, []);

  useEffect(() => {
    const duration = selectedServices.reduce((total, service) => {
      return total + (service.modified_duration || service.duration);
    }, 0);
    setTotalDuration(duration);

    if (duration > 0) {
      loadAvailableSlots(duration);
    }
  }, [selectedServices]);

  const loadCustomers = async () => {
    try {
      setLoading(true);
      const response = await apiService.getCustomers();
      setCustomers(response.data || []);
    } catch (err) {
      setError('Failed to load customers');
    } finally {
      setLoading(false);
    }
  };

  const loadAllVehicles = async () => {
    try {
      const response = await apiService.getVehicles();
      setAllVehicles(response.data || []);
    } catch (err) {
      console.error('Failed to load vehicles:', err);
    }
  };

  const loadServices = async () => {
    try {
      const response = await apiService.getServices();
      setServices(response.data || []);
    } catch (err) {
      console.error('Failed to load services:', err);
    }
  };

  const loadServiceTemplates = async () => {
    try {
      const response = await apiService.getServiceTemplates();
      setServiceTemplates(response.data || []);
    } catch (err) {
      console.error('Failed to load service templates:', err);
    }
  };

  const loadAvailableSlots = async (duration) => {
    try {
      const response = await apiService.getAvailableSlots({
        duration: duration,
        start_date: new Date().toISOString().split('T')[0]
      });
      setAvailableSlots(response.data || []);
    } catch (err) {
      console.error('Failed to load available slots:', err);
    }
  };

  const handleCustomerCreated = async (newCustomer) => {
    if (newCustomer) {
      setCustomers(prevCustomers => [...prevCustomers, newCustomer]);
      setSelectedCustomer(newCustomer);
    }
    setCustomerDialogOpen(false);
  };

  const handleVehicleCreated = async (newVehicle) => {
    if (newVehicle) {
      setAllVehicles(prevVehicles => [...prevVehicles, newVehicle]);
      setSelectedVehicle(newVehicle);

      if (selectedCustomer && selectedCustomer.id) {
        try {
          await apiService.addOwnership({
            vehicle_id: parseInt(newVehicle.id),
            customer_id: parseInt(selectedCustomer.id),
            start_date: new Date().toISOString().split('T')[0]
          });
        } catch (err) {
          console.error('Error creating ownership:', err);
        }
      }
    }
    setVehicleDialogOpen(false);
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    if (template) {
      setSelectedServices(template.services.map(s => ({
        ...s,
        modified_cost: s.modified_cost || s.cost,
        modified_duration: s.modified_duration || s.duration
      })));
    } else {
      setSelectedServices([]);
    }
  };

  const handleServiceSelect = (service) => {
    if (!service) return;
    
    const exists = selectedServices.find(s => s.id === service.id);
    if (!exists) {
      setSelectedServices([...selectedServices, {
        ...service,
        modified_cost: service.cost,
        modified_duration: service.duration
      }]);
    }
  };

  const handleRemoveService = (serviceId) => {
    setSelectedServices(selectedServices.filter(s => s.id !== serviceId));
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
  try {
    setLoading(true);
    setError('');

    if (!selectedCustomer || !selectedVehicle || 
        !bookingData.requested_date || !bookingData.requested_time_slot || 
        selectedServices.length === 0) {
      setError('Please fill in all required fields');
      return;
    }

    const bookingPayload = {
      customer_id: selectedCustomer.id,
      vehicle_id: selectedVehicle.id,
      requested_date: bookingData.requested_date,
      requested_time_slot: bookingData.requested_time_slot,
      notes: bookingData.notes,
      technician_ids: selectedTechnicians, 
      services: selectedServices.map(service => ({
        service_id: service.id,
        modified_cost: service.modified_cost,
        modified_duration: service.modified_duration
      }))
    };

    await apiService.createBooking(bookingPayload);
    navigate('/bookings');
  } catch (err) {
    setError('Failed to create booking');
  } finally {
    setLoading(false);
  }
};

const renderTechnicianSelection = () => (
  <Grid item xs={12}>
    <Autocomplete
      options={technicians}
      getOptionLabel={(tech) => tech.name}
      value={selectedTechnician}
      onChange={(_, newValue) => setSelectedTechnician(newValue)}
      renderInput={(params) => (
        <TextField {...params} label="Assign Technician" required />
      )}
    />
  </Grid>
);
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={customers}
                  getOptionLabel={(option) => `${option.name} (${option.mobile || option.phone || 'No contact'})`}
                  value={selectedCustomer}
                  onChange={(_, newValue) => setSelectedCustomer(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Customer" required />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => setCustomerDialogOpen(true)}
                >
                  Create New Customer
                </Button>
              </Grid>
            </Grid>
          </Box>
        );

      case 1:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={allVehicles}
                  getOptionLabel={(option) => 
                    `${option.rego || option.stock || '?'} ${option.year || '?'}
                    ${option.make || '?'} ${option.model || '?'} ${option.battery || '?'}${option.grade||'?'} ${option.vin||'??'}`
                  }
                  value={selectedVehicle}
                  onChange={(_, newValue) => setSelectedVehicle(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Vehicle" required />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => setVehicleDialogOpen(true)}
                >
                  Add New Vehicle
                </Button>
              </Grid>
            </Grid>
          </Box>
        );

      case 2:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useTemplate}
                      onChange={(e) => {
                        setUseTemplate(e.target.checked);
                        setSelectedServices([]);
                        setSelectedTemplate(null);
                      }}
                    />
                  }
                  label="Use Service Template"
                />
              </Grid>

              {useTemplate ? (
                <Grid item xs={12}>
                  <Autocomplete
                    options={serviceTemplates}
                    getOptionLabel={(option) => option.name}
                    value={selectedTemplate}
                    onChange={(_, newValue) => handleTemplateSelect(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Service Template" />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Autocomplete
                    options={services}
                    getOptionLabel={(option) => `${option.name} (${option.duration} mins)`}
                    onChange={(_, newValue) => handleServiceSelect(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Service" />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell align="right">Duration</TableCell>
                        <TableCell align="right">Cost</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedServices.map((service) => (
                        <TableRow key={service.id}>
                          <TableCell>{service.name}</TableCell>
                          <TableCell align="right">
                            {service.modified_duration || service.duration} mins
                          </TableCell>
                          <TableCell align="right">
                            ${service.modified_cost || service.cost}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              size="small"
                              color="error"
                              onClick={() => handleRemoveService(service.id)}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="subtitle1">
                            Total Duration:
                          </Typography>
                        </TableCell>
                        <TableCell align="right" colSpan={2}>
                          <Chip
                            icon={<TimerIcon />}
                            label={`${totalDuration} mins`}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        );

// In BookingForm.jsx, update the scheduling step case:
case 3: // Scheduling Step
  return (
    <Box>
      <BookingDateSelector
        availableSlots={availableSlots}
        selectedDate={bookingData.requested_date ? parseISO(bookingData.requested_date) : null}
        selectedTime={bookingData.requested_time_slot}
        onDateChange={(newDate) => {
          setBookingData({
            ...bookingData,
            requested_date: format(newDate, 'yyyy-MM-dd')
          });
        }}
        onTimeChange={(newTime) => {
          setBookingData({
            ...bookingData,
            requested_time_slot: newTime
          });
        }}
        technicians={technicians}
        selectedTechnicians={selectedTechnicians}
        onTechniciansChange={setSelectedTechnicians}
        bookingNotes={bookingData.notes}
        onNotesChange={(newNotes) => {
          setBookingData({
            ...bookingData,
            notes: newNotes
          });
        }}
        submitted={submitted}
      />
    </Box>
  );
  return (
    <Box>
      <Grid container spacing={3}>
        {/* Date and Time Selection with Existing Bookings */}
        <Grid item xs={12} md={6}>
          <BookingDateSelector
            availableSlots={availableSlots}
            selectedDate={bookingData.requested_date ? parseISO(bookingData.requested_date) : null}
            selectedTime={bookingData.requested_time_slot}
            onDateChange={(newDate) => {
              setBookingData({
                ...bookingData,
                requested_date: format(newDate, 'yyyy-MM-dd')
              });
            }}
            onTimeChange={(newTime) => {
              setBookingData({
                ...bookingData,
                requested_time_slot: newTime
              });
            }}
          />
        </Grid>

        {/* Technician Selection and Notes */}
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Assign Technicians
              </Typography>
              <TechnicianSelection
                technicians={technicians}
                selectedTechnicians={selectedTechnicians}
                onChange={setSelectedTechnicians}
                required
                error={submitted && selectedTechnicians.length === 0 ? 
                      'At least one technician is required' : null}
              />
            </Paper>

            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Additional Notes
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Add any special instructions or notes here..."
                value={bookingData.notes}
                onChange={(e) => setBookingData({
                  ...bookingData,
                  notes: e.target.value
                })}
              />
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );

     default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/bookings')}
          sx={{ mb: 2 }}
        >
          Back to Bookings
        </Button>
        <Typography variant="h4" component="h1">
          New Booking
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={loading || 
              (activeStep === 0 && !selectedCustomer) ||
              (activeStep === 1 && !selectedVehicle) ||
              (activeStep === 2 && selectedServices.length === 0) ||
              (activeStep === 3 && !bookingData.requested_date)
            }
          >
            {loading ? <CircularProgress size={24} /> : 
              activeStep === steps.length - 1 ? 'Create Booking' : 'Next'}
          </Button>
        </Box>
      </Paper>

      {/* Customer Creation Dialog */}
      <Dialog 
        open={customerDialogOpen} 
        onClose={() => setCustomerDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Customer</DialogTitle>
        <DialogContent>
          <CustomerForm 
            onSuccess={handleCustomerCreated}
            isDialog={true}
          />
        </DialogContent>
      </Dialog>

      {/* Vehicle Creation Dialog */}
      <Dialog 
        open={vehicleDialogOpen} 
        onClose={() => setVehicleDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Vehicle</DialogTitle>
        <DialogContent>
          <VehicleForm 
            customerId={selectedCustomer?.id}
            onSuccess={handleVehicleCreated}
            isDialog={true}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BookingForm;